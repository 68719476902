.grid {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}

.grid label span,
.grid h4 {
  color: clr1 !important;
  filter: brightness(25%);
}
.grid > * {
  width: 90%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-box-shadow: 0 0 0px 1000px clr4 inset;
}

.rentadjustment .name {
  font-weight: bold;
}

.rentadjustment .break {
  height: 1rem;
}

.heading {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.heading img {
  max-height: 2.5cm;
}

.ownerdetail {
  margin: auto;
  margin-right: 1rem;
  align-items: right;
}

.heading {
  max-height: 250px;
  height: 180px;
}

.banner {
  height: 100px;
}

.recipient_w_header {
  position: relative;
  top: 25px;
}

.recipient {
  position: relative;
  top: 100px;
}

.l1,
.l2,
.l3,
.l4,
.l11,
.l12 {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.l8,
.l9,
.l10 {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.l3 {
  text-decoration: underline;
  font-weight: bold;
}

.l5 {
  border-bottom: 1px solid black !important;
}

.l5,
.l6 {
  margin-left: auto;
  margin-right: auto;
}

.l10 {
  font-weight: bold;
}

.l13 {
  margin-left: 40%;
  margin-top: 3rem;
}

.l13 img {
  width: 20%;
}

.rentadjustment {
  width: 100vw;
  padding: 2rem;
  max-width: 21cm;
}

.heading {
  padding-top: 3rem;
}

@media print {
  .l13 {
    margin-left: 10cm;
    margin-top: 3cm;
  }

  .l13 img {
    width: 200px;
  }

  .rentadjustmentheader,
  .rentadjustment {
    width: 21cm;
  }

  .rentadjustment {
    padding: 1.5cm;
  }

  body * {
    visibility: hidden;
    margin: 0;
    padding: 0;
  }

  .Header {
    display: none;
  }

  .rentadjustmentheader *,
  .rentadjustment * {
    visibility: visible;
  }

  .rentadjustment {
    padding: 1cm;
    top: 0px;
    left: 0px;
    width: 19.5cm;
  }

  .pagebreak {
    page-break-before: always;
  }

  .l1,
  .l2,
  .l3,
  .l8,
  .l9,
  .l10 {
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
  }
  .l1 {
    margin-top: 3rem;
  }

  .l8,
  .l3 {
    margin-top: 2rem;
  }
}
